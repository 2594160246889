<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <a href="javascript:;">Home</a>
      </li>
    </ol>
    <h1 class="page-header">
      สรุปผลการนับคะแนน นายก อบจ.
      <small>รายงานผลอย่างไม่เป็นทางการ</small>
    </h1>
    <panel>
      <template slot="header">
        <h4 class="panel-title">
          ผลคะแนนเลือกตั้ง นายก อบจ.สุรินทร์
          <span class="text-red-darker">(Refresh ข้อมูลในอีก {{ timerCount }} วินาที)</span>
        </h4>
      </template>
      <div class="text-center">
        <div class="form-inline justify-content-center">
          <div class="me-10">
            <b-img src="/assets/img/logo/surinpao-logo.png" rounded="circle" alt="Circle image" width="120px"></b-img>
            <b-img src="/assets/img/logo/election-logo.png" rounded="circle" alt="Circle image" width="120px" class="ms-3"></b-img>
          </div>
          <div class="pt-3">
            <div class="fs-40px">ผลคะแนนเลือกตั้ง นายก อบจ.สุรินทร์</div>
            <div class="fs-20px text-red-600">รายงานผลอย่างไม่เป็นทางการ</div>
            <div>ณ วันที่ {{ summary.curr_time }} น.</div>
          </div>
        </div>
      </div>

      <b-container fluid>
        <b-row>
          <b-col cols="9">
            <b-container fluid class="p-0">
              <b-row>
                <b-col v-for="applicant in app_provs" :key="applicant.id" class="ps-1 pe-1">
                  <div class="text-center fs-50px fw-bold">
                    <small class="fs-25px">หมายเลข</small>
                    {{ applicant.number }}
                  </div>
                  <b-card no-body :img-src="applicant.img_path" img-alt="Image" img-top tag="article" style="max-width: 25rem" class="mb-2">
                    <b-card-body class="pt-3 ps-1 pe-1">
                      <b-card-title>{{ applicant.full_name }}</b-card-title>
                      <b-card-text class="p-1">
                        <div class="text-end fs-35px fw-bold">
                          {{ parseInt(applicant.vote).toLocaleString() }}
                          <small class="fs-16px">คะแนน</small>
                        </div>
                      </b-card-text>
                    </b-card-body>
                    <template #footer>
                      <div class="text-center">
                        <small class="text-muted text-red-600 fs-20px fw-bold">ลำดับที่ {{ applicant.no }}</small>
                      </div>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-col cols="3">
            <b-card bg-variant="light" sub-title="ผลคะแนนเลือกตั้ง นายก อบจ.สุรินทร์" class="m-0" style="height: 100%">
              <apexchart type="bar" ref="chart" :options="mixedChart.options" :series="mixedChart.series" height="380"></apexchart>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <b-container fluid>
        <b-row class="mt-3">
          <b-col cols="2" class="fs-18px mt-3 text-end">ยืนยันผลเลือกตั้ง (หน่วย): </b-col>
          <b-col cols="10">
            <b-progress :value="summary.send" :max="summary.units" animated height="45px">
              <b-progress-bar :value="summary.send">
                <span class="fs-30px">
                  <strong>{{ summary.send.toLocaleString() }} / {{ summary.units.toLocaleString() }}</strong>
                </span>
              </b-progress-bar>
            </b-progress>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="2" class="fs-18px mt-3 text-end">ผู้มาใช้สิทธิ์เลือกตั้ง (ราย): </b-col>
          <b-col cols="10">
            <b-progress :value="summary.comein" :max="summary.elector" animated height="45px" variant="warning">
              <b-progress-bar :value="summary.comein">
                <span class="fs-30px">
                  <strong>{{ summary.comein.toLocaleString() }} / {{ summary.elector.toLocaleString() }}</strong>
                </span>
              </b-progress-bar>
            </b-progress>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="2" class="fs-17px mt-3 text-end">บัตรดี, บัตรเสีย, ไม่ลงคะแนน: </b-col>
          <b-col cols="10">
            <b-progress show-value striped :max="summary.good + summary.fail + summary.no_vote" class="mb-3" height="45px">
              <b-progress-bar variant="success" :value="summary.good">
                <span class="fs-30px">
                  <strong>{{ summary.good.toLocaleString() }}</strong>
                </span>
              </b-progress-bar>
              <b-progress-bar variant="warning" :value="summary.fail">
                <span class="fs-18px">
                  <strong>{{ summary.fail.toLocaleString() }}</strong>
                </span>
              </b-progress-bar>
              <b-progress-bar variant="danger" :value="summary.no_vote">
                <span class="fs-18px">
                  <strong>{{ summary.no_vote.toLocaleString() }}</strong>
                </span>
              </b-progress-bar>
            </b-progress>
          </b-col>
        </b-row>
      </b-container>

      <b-container fluid>
        <b-row>
          <b-col>
            <b-card bg-variant="light" class="mt-5">
              <apexchart type="pie" :options="pieUnit.options" :series="pieUnit.series"></apexchart>
            </b-card>
          </b-col>
          <b-col>
            <b-card bg-variant="light" class="mt-5">
              <apexchart type="pie" :options="pieElector.options" :series="pieElector.series"></apexchart>
            </b-card>
          </b-col>
          <b-col>
            <b-card bg-variant="light" class="mt-5">
              <apexchart type="pie" :options="pieCard.options" :series="pieCard.series"></apexchart>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </panel>
  </div>
</template>

<script>
export default {
  name: 'ReportProvince',
  data() {
    return {
      election_unit_all: 2356,
      election_unit_pro: 1540,
      election_people_all: 1082341,
      election_people_pro: 245389,
      refreshSec: 5,
      timerCount: 5,
      summary: {
        send: 0,
        units: 0,
        comein: 0,
        elector: 0,
        good: 0,
        fail: 0,
        no_vote: 0,
        curr_time: '',
      },
      app_provs: [],
      pieUnit: {
        options: {
          chart: {
            height: 365,
            type: 'pie',
          },
          dataLabels: {
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              opacity: 0.45,
            },
          },
          colors: ['#f59c1a', '#348fe2'],
          labels: ['ส่งผลแล้ว', 'ยังไม่ส่งผล'],
          title: {
            text: 'สถิติหน่วยนับที่ส่งผลการเลือกตั้ง (หน่วย)',
          },
        },
        series: [],
      },
      pieElector: {
        options: {
          chart: {
            height: 365,
            type: 'pie',
          },
          dataLabels: {
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              opacity: 0.45,
            },
          },
          colors: ['#fb5597', '#f59c1a'],
          labels: ['ผู้มาใช้สิทธิ', 'ผู้ไม่มาใช้สิทธิ'],
          title: {
            text: 'สถิติผู้มาใช้สิทธิเลือกตั้ง (ราย)',
          },
        },
        series: [],
      },
      pieCard: {
        options: {
          chart: {
            height: 365,
            type: 'pie',
          },
          dataLabels: {
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              opacity: 0.45,
            },
          },
          colors: ['#348fe2', '#00acac', '#8753de'],
          labels: ['บัตรดี', 'บัตรเสีย', 'ไม่ลงคะแนน'],
          title: {
            text: 'สถิติบัตรเลือกตั้ง (ใบ)',
          },
        },
        series: [],
      },
      mixedChart: {
        options: {
          chart: {
            height: '100%',
            type: 'column',
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [0, 0, 3],
          },
          colors: ['#276baa', '#00acac', '#f59c1a'],
          title: {
            text: 'ผลคะแนน',
            align: 'left',
            offsetX: 150,
          },
          xaxis: {
            categories: ['เบอร์ 1', 'เบอร์ 2', 'เบอร์ 3', 'เบอร์ 4', 'เบอร์ 5'],
            axisBorder: {
              show: true,
              color: 'rgba(182, 194, 201, 0.5)',
              height: 1,
              width: '100%',
              offsetX: 0,
              offsetY: -1,
            },
            axisTicks: {
              show: true,
              borderType: 'solid',
              color: '#b6c2c9',
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#276baa',
              },
              labels: {
                style: {
                  color: '#276baa',
                },
              },
              title: {
                text: 'คะแนนเลือกตั้ง',
                style: {
                  color: '#276baa',
                },
              },
            },
          ],
        },
        series: [
          {
            name: 'คะแนน',
            type: 'column',
            data: [],
          },
        ],
      },
    }
  },
  mounted() {
    this.getApplicantProv()
    this.getSummary()
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
            if (this.timerCount == 0) {
              this.getApplicantProv()
              this.getSummary()
              this.timerCount = this.refreshSec
            }
          }, 1000)
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    getSummary() {
      let url = `election_units?t=get_summary`
      this.axios.get(url).then((res) => {
        this.summary.send = parseInt(res.data.send)
        this.summary.units = parseInt(res.data.units)
        this.summary.comein = parseInt(res.data.comein)
        this.summary.elector = parseInt(res.data.elector)
        this.summary.good = parseInt(res.data.good)
        this.summary.fail = parseInt(res.data.fail)
        this.summary.no_vote = parseInt(res.data.no_vote)
        this.summary.curr_time = res.data.curr_time

        this.pieUnit.series = [parseInt(res.data.send), parseInt(res.data.units) - parseInt(res.data.send)]
        this.pieElector.series = [res.data.comein ? parseInt(res.data.comein) : 0, parseInt(res.data.elector) - (res.data.comein ? parseInt(res.data.comein) : 0)]
        this.pieCard.series = [parseInt(res.data.good), parseInt(res.data.fail), parseInt(res.data.no_vote)]
      })
    },
    getApplicantProv() {
      let url = `applicant_provinces?t=get_score`
      this.axios.get(url).then((res) => {
        this.app_provs = res.data.data
        this.$refs.chart.updateSeries([
          {
            name: 'คะแนน',
            type: 'column',
            data: [res.data.score['prov_score_1'], res.data.score['prov_score_2'], res.data.score['prov_score_3'], res.data.score['prov_score_4'], res.data.score['prov_score_5']],
          },
        ])
      })
    },
  },
}
</script>

<style>
.height-75 {
  height: 75px !important;
}
</style>
